<template>
    <div class="mobile-navigation">
        <nav class="offcanvas-navigation" id="offcanvas-navigation">
            <ul>
                <li class="menu-item-has-children">
                    <n-link to="/">Home</n-link>
                    <ul class="sub-menu">
                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Home Samples 01</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/"><span>Home Business</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-creative-agency"><span>Home Creative Agency</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-design-studio"><span>Home Design Studio</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-digital-agency"><span>Home Digital Agency</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-authentic-studio"><span>Home Authentic Studio</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-minimal-agency"><span>Home Minimal Agency</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-presentation"><span>Home Presentation</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Home Samples 02</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/home-start-up"><span>Home Start Ups</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-freelancer"><span>Home Freelancer</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-product-landing"><span>Home Product Landing</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-restaurant"><span>Home Restaurant</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-photo-slider-gallery"><span>Home Photo Slider Gallery</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-onepage"><span>Home Onepage</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-type-hover"><span>Home Portfolio Type Hover</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Home Samples 03</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/home-landing"><span>Home Landing</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-service"><span>Home Service</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-vertical-menu"><span>Home Vertical Menu</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-corona"><span>Home Corona</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-essential"><span>Home Essential</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-indie-musician"><span>Indie Musician</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Home Samples 04</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/home-creative-portfolio"><span>Home Creative Portfolio</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-minimal-portfolio"><span>Home Minimal Portfolio</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-portfolio-slide"><span>Home Portfolio Slide</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-fullscreen-slider-left-vertical-header"><span>Portfolio Fullscreen Slider</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-blog-grid"><span>Home Blog Grid</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-masonry-gallery"><span>Home Masonry Gallery</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-minimal-metro-grid"><span>Home Minimal Metro Grid</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/home-foodie"><span>Home Foodie</span></nuxt-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="menu-item-has-children">
                    <n-link to="/">Pages</n-link>
                    <ul class="sub-menu">
                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Sample Pages 01</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/about-us-01"><span>About Us 01</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/about-us-02"><span>About Us 02</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/about-us-03"><span>About Us 03</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Sample Pages 02</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/services-classic"><span>Services Classic</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/services-modern"><span>Services Modern</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/services-list"><span>Services List</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Sample Pages 03</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/team-grid"><span>Team Grid</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/team-carousel-01"><span>Our Team Carousel 01</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/team-carousel-02"><span>Our Team Carousel 02</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Sample Pages 04</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/contact-us-modern"><span>Contact Us Modern</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/contact-us-with-map"><span>Contact Us With Map</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/pricing-plans"><span>Pricing Plans</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/pricing-plans-02"><span>Pricing Plans 02</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/career"><span>Career</span></nuxt-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="menu-item-has-children">
                    <n-link to="/">Portfolio</n-link>
                    <ul class="sub-menu">
                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Portfolio Samples 02</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-grid-boxed"><span>Portfolio Grid Boxed</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-grid-wide"><span>Portfolio Grid Wide</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-with-caption"><span>Portfolio With Caption</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Portfolio Samples 02</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-grid-metro-01"><span>Portfolio Grid Metro 01</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-grid-metro-02"><span>Portfolio Grid Metro 02</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-ajax-filter"><span>Portfolio Ajax Filter</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Portfolio Details 01</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-grid-filter"><span>portfolio-grid-filter</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-details"><span>Portfolio Details</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-details-right"><span>Portfolio Details Right</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Portfolio Details 02</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-details-left-wide"><span>Left Details - Wide</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-details-right-wide"><span>Right Details - Wide</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/portfolio/portfolio-details-image-with-caption"><span>Image With Caption</span></nuxt-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="menu-item-has-children">
                    <n-link to="/">Elements</n-link>
                    <ul class="sub-menu">
                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Element Group 01</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/element/team"><span>Team</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/testimonial"><span>Testimonial</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/pricing-box"><span>Pricing Box</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/dividers"><span>Dividers</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Element Group 02</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/element/brand-logo"><span>Brand Logo Carousel</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/flexible-image-slider"><span>Flexible Image Slider</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/carousel-slider"><span>Carousel Slider</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/call-to-action-banner"><span>Call To Action Banner</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Element Group 03</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/element/tab"><span>Tab</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/gradation"><span>Gradation</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/message-box"><span>message-box</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/instagram"><span>Instagram</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Element Group 04</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/element/icon-boxes"><span>icon-boxes</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/lists"><span>Lists</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/accordion"><span>Accordion</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/element/contact-subscribe"><span>Contact & subscribe forms</span></nuxt-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="menu-item-has-children">
                    <n-link to="/">Blogs</n-link>
                    <ul class="sub-menu">
                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Blog Group 01</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/blog/blog-creative"><span>Blog Creative</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/blog/blog-standard-list"><span>Blog Standard List</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/blog/blog-grid-classic"><span>Blog Grid Classic</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Blog Group 02</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/blog/blog-grid-classic-sidebar"><span>Blog Grid Classic Sidebar</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/blog/blog-grid-standard"><span>Blog Grid Standard</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/blog/blog-grid-simple"><span>Blog Grid Simple</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Blog Group 03</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/blog/blog-grid-modern"><span>Blog Grid Modern</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/blog/blog-grid-minimal"><span>Blog Grid Minimal</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/blog/blog-grid-minimal-outline"><span>Blog Grid Minimal Outline</span></nuxt-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mega--title menu-item-has-children">
                            <n-link to="">Blog Group 04</n-link>
                            <ul class="sub-menu">
                                <li>
                                    <nuxt-link to="/blog/blog-grid-mesonry"><span>Blog Grid Masonry</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/blog/blog-details"><span>Blog Details</span></nuxt-link>
                                </li>
                                <li>
                                    <nuxt-link to="/blog/blog-details-modern-layout"><span>Blog Details Modern Layout</span></nuxt-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default{
        name: 'MobileNavMenu',
        mounted() {
            const offCanvasNav = document.querySelector('#offcanvas-navigation');
            const offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');
            const anchorLinks = offCanvasNav.querySelectorAll('a');
        
            for (let i = 0; i < offCanvasNavSubMenu.length; i++){
                offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
            }
        
            const menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
            const numMenuExpand = menuExpand.length;
        
            for (let i = 0; i < numMenuExpand; i++) {
                menuExpand[i].addEventListener("click", (e) => {sideMenuExpand(e)});
            }
        
            for (let i = 0; i < anchorLinks.length; i++) {
                anchorLinks[i].addEventListener("click", () => {closeMobileMenu()});
            }

            const sideMenuExpand = (e) => {
                e.currentTarget.parentElement.classList.toggle('active');
            }
            const closeMobileMenu = () => {
                const offcanvasMobileMenu = document.querySelector('#offcanvas-mobile-menu');
                offcanvasMobileMenu?.classList.remove('active');
            }
        }
    };
</script>

<style lang="scss">
    .offcanvas-navigation {
        ul {
            li {
                &.menu-item-has-children {
                    &.mega--title > {
                        a {
                            font-weight: 500;
                        }
                    }
                    .sub-menu {
                        height: 0;
                        opacity: 0;
                        transition: 0.3s;
                        visibility: hidden;
                    }
                    &.active > {
                        .sub-menu {
                            height: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                a {
                    color: $white;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 10px 0;
                    display: block;
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }

        ul {
            &.sub-menu {
                margin-left: 15px;
                transition: 0.4s;
                li {
                    a {
                        color: $white;
                        font-weight: 400;
                        &:hover {
                            color: $theme-color;
                        }
                    }
                    .sub-menu {
                        li {
                            a {
                                font-size: 13px;
                                padding: 5px 0;
                            }
                        }
                    }
                }
            }
            li {
                &.menu-item-has-children {
                    position: relative;
                    display: block;
                    a {
                        display: inline-block;
                    }

                    &.active > {
                        .menu-expand {
                            background-color: rgba($white, 0.2);
                            i {
                                &:before {
                                    transform: rotate(0);
                                }
                            }
                        }
                    }

                    .menu-expand {
                        top: 5px;
                        right: 0;
                        width: 35px;
                        height: 35px;
                        cursor: pointer;
                        line-height: 35px;
                        position: absolute;
                        text-align: center;
                        background-color: rgba($white, 0.1);
                        &:hover {
                            background-color: rgba($white, 0.2);
                        }

                        i {
                            display: block;
                            border-bottom: 1px solid #ffffff;
                            position: relative;
                            width: 10px;
                            text-align: center;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            &:before {
                                width: 100%;
                                content: "";
                                border-bottom: 1px solid $white;
                                display: block;
                                position: absolute;
                                top: 0;
                                transform: rotate(90deg);
                                transition: 0.4s;
                            }
                        }
                    }
                }
            }
        }
    }
</style>